<template>
  <div class="parameters">
    <PageTitle title="進場紀錄" hideBtn style="margin-bottom: 64px" />
    <div class="parameters-category-list">
      <router-link
        v-for="item in storeList"
        class="category-block"
        :key="item.id"
        :to="{ name: 'EntryControlRecordList', params: { id: item.id } }"
      >
        <span>{{ item.name }}</span>
        <span class="material-icons">chevron_right</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import { defineComponent, ref, onMounted } from 'vue'
import { useShop } from '@/use/shop'
import { GetBranchStore } from '@/api/branchStore'

export default defineComponent({
  name: 'EntryControlBranch',
  components: { PageTitle },
  setup() {
    const { shopId } = useShop()
    const storeList = ref([])
    const getBranch = async () => {
      const [res, err] = await GetBranchStore({
        shopId: shopId.value,
      })
      if (err) window.$message.error(err)
      storeList.value = res
    }
    onMounted(() => {
      getBranch()
    })
    return {
      storeList,
    }
  },
})
</script>

<style lang="postcss" scoped>
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
.category-block {
  @apply border-primary-100 border py-[16px] px-[24px] rounded-[6px] w-[280px] bg-white text-normal;
  @apply flex justify-between;
}
</style>
